import React, { useCallback } from 'react';
import s from '../header/index.module.scss';
import Image from 'next/image';
import cn from 'classnames';
import { useEffect, useState, useRef } from 'react';
import { platform, isMobile } from 'utils/index';
import getStore from 'utils/store';
import { navBarItem } from './config';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import LocaleSwitch from 'components/localeSwitch';
import Button from 'components/button';
import { joinUsUrl } from 'constants/index';
import { handleHonerLinkClick } from '../header/config';
import CustomLink from 'components/link';

interface IProps {
  onToggleApp: (visible: boolean) => void;
  headerClass?: string;
}

interface INavItem {
  name: string;
  url: string;
  children?: React.ReactNode;
  external?: boolean;
  left?: number;
}

function Header(props: IProps) {
  const { t } = useTranslation('common');
  const router = useRouter();
  const { locale } = router;
  const [clientWidth, setClientWidth] = useState(0);
  const [showMenu, setShowMenu] = useState(false);
  const [showApp, setShowApp] = useState(false);
  const [navbar, setNavbar] = useState<INavItem[]>([
    {
      name: t('nav.productService'),
      url: `/platform`,
      children: (
        <>
          {navBarItem([
            {
              //超融合视频平台
              name: t('nav.superMerge'),
              url: `/platform`,
              show: true,
              needTitle: true,
              locale: locale,
            },
            {
              // 全连接视频终端
              name: t('nav.contactAll'),
              url: `/hardware`,
              show: true,
              needTitle: true,
              locale: locale,

              children: [
                //智能硬件
                { name: t('nav.product.cloud.hardware'), url: `/hardware` },
                //软件客户端
                { name: t('nav.product.cloud.Client'), url: `/client` },
              ],
            },
            {
              // 泛业务开发底座
              name: t('nav.api'),
              url: `/sdk_api`,
              show: true,
              test: true,
              needTitle: true,
              locale: locale,
            },
          ])}
        </>
      ),
    },
    {
      name: t('nav.solutions'),
      url: '/gov-solution',
      children: (
        <>
          {navBarItem([
            {
              needTitle: false,
              children: [
                {
                  name: t('nav.solution.government'),
                  url: '/gov-solution',
                },
                {
                  name: t('nav.solution.politicsLaw'),
                  url: '/court-solution',
                },
                {
                  name: t('nav.solution.enterprise'),
                  url: '/group-solution',
                },
                {
                  name: t('nav.solution.financial'),
                  url: '/finance-solution',
                },
              ],
            },
            {
              needTitle: false,
              children: [
                {
                  name: t('nav.solution.education'),
                  url: '/edu-solution',
                },
                {
                  name: t('nav.solution.medical'),
                  url: '/med-solution',
                },
                {
                  name: t('nav.solution.WaterConservancy'),
                  url: '/watercon-solution',
                },
                {
                  name: t('nav.solution.emergency'),
                  url: '/er-solution',
                },
              ],
            },
          ])}
        </>
      ),
    },
    { name: t('nav.advantages'), url: '/advantage' },
    {
      name: t('nav.aboutUs'),
      url: '/about/about',
      external: true,
      children: (
        <>
          {navBarItem([
            {
              needTitle: false,
              children: [
                {
                  name: t('nav.companyIntroductionEn'),
                  url: '/about/about',
                },
                {
                  name: t('nav.companyHonorEn'),
                  url: '/about/about#honer',
                },
                {
                  name: t('nav.companyNews'),
                  url: '/news',
                },
              ],
            },
            {
              needTitle: false,
              children: [
                {
                  name: t('nav.contactUs'),
                  url: '/about/contact',
                },
                {
                  name: t('nav.joinUs'),
                  url: joinUsUrl,
                },
              ],
            },
          ])}
        </>
      ),
    },
    { name: t('nav.downloadCenter'), url: '/download', external: true },
  ]);
  const navRef = useRef<HTMLUListElement>(null);

  const onToggleActive = (e: any) => {
    try {
      const currTarget = e.currentTarget;
      const brother = currTarget.nextElementSibling || currTarget.nextSibling;
      if (brother.className.indexOf('active') > -1) {
        currTarget.className = currTarget.className.replace(/\s+active/g, '');
        brother.className = brother.className.replace(/\s+active/g, '');
      } else {
        currTarget.className += ' active';
        brother.className += ' active';
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    props.onToggleApp(showApp);
  }, [props, showApp]);

  useEffect(() => {
    const isPhone = isMobile();
    const store = getStore();
    if (isPhone) {
      const userAgent = navigator.userAgent;
      if (userAgent.indexOf('XYLINK') >= 0) {
        setShowApp(false);
        return;
      }
      if (store.get('isCloseScanner') * 1 === 1) {
        const startTime = store.get('startTime') * 1;
        if (new Date().getTime() - startTime > 24 * 3600 * 1000) {
          setShowApp(true);
          store.set('isCloseScanner', 0);
          // 可有可无
          store.set('startTime', new Date().getTime());
        }
      } else {
        setShowApp(true);
        store.set('isCloseScanner', 0);
      }
    }
  }, []);

  const statisticTry = () => {
    const { _hmt = [] } = window;
    if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
      _hmt.push(['_trackEvent', 'header', '立即试用mobile']);
    } else {
      _hmt.push(['_trackEvent', 'header', '立即试用pc']);
    }
  };

  const openApp = () => {
    let openHash;
    const plat = platform();
    const { _hmt = [] } = window;
    if (plat.weixin) {
      if (plat.ios) {
        openHash = 'ios-wx';
        _hmt.push(['_trackEvent', '狰', 'weixindakaiApple']);
        _hmt.push(['_trackEvent', '狰', '微信打开iOS']);
      } else {
        openHash = 'android-wx';
        _hmt.push(['_trackEvent', '政', '微信打开Android']);
      }
    } else {
      if (plat.ios) {
        openHash = 'ios-browser';
        _hmt.push(['_trackEvent', '狰', '浏览器打开iOS']);
      } else {
        openHash = 'android-browser';
        _hmt.push(['_trackEvent', '政', '浏览器打开Android']);
      }
    }
    window.location.href = '/download?source=' + openHash;
  };

  const deleteApp = () => {
    setShowApp(false);
    const store = getStore();
    store.set('isCloseScanner', 1);
    store.set('startTime', new Date().getTime());
  };

  const mobileNav = [
    {
      link: '/platform',
      text: t('nav.productService'),
      key: 'platform',
      children: [
        //超融合视频平台
        {
          link: '/platform',
          text: t('nav.superMerge'),
          key: 'merge',
        },
        //全连接视频终端
        {
          link: '/hardware',
          text: t('nav.contactAll'),
          key: 'contactAll',
          children: [
            //智能硬件
            { link: '/hardware', text: t('nav.product.cloud.hardware'), key: 'hard' },
            //软件客户端
            { link: '/client', text: t('nav.product.cloud.Client'), key: 'client' },
          ],
        },
        // 泛业务开发底座
        {
          link: '/sdk_api',
          text: t('nav.api'),
          key: 'api',
        },
      ],
    },
    {
      link: '/gov-solution',
      text: t('nav.solutions'),
      key: 'solution',
      children: [
        { link: '/gov-solution', text: t('nav.solution.government'), key: 'judicature' },
        { link: '/court-solution', text: t('nav.solution.politicsLaw'), key: 'politicsLaw' },
        { link: '/group-solution', text: t('nav.solution.enterprise'), key: 'group' },
        { link: '/finance-solution', text: t('nav.financeIndustry'), key: 'finance' },
        { link: '/edu-solution', text: t('nav.eduIndustry'), key: 'education' },
        { link: '/med-solution', text: t('nav.healIndustry'), key: 'medical' },
        { link: '/watercon-solution', text: t('nav.solution.WaterConservancy'), key: 'WaterConservancy' },
        { link: '/er-solution', text: t('nav.solution.emergency'), key: 'emergency' },
      ],
    },
    { link: '/advantage', text: t('nav.advantages'), key: 'advantage' },
    {
      link: '/about/about',
      text: t('nav.aboutUs'),
      key: 'about',
      children: [
        { link: '/about/about', text: t('nav.companyIntroduction'), key: 'introduce' },
        { link: '/about/about#honer', text: t('nav.companyHonor'), key: 'honer' },
        { link: '/news', text: t('nav.companyNews'), key: 'news' },
        { link: '/about/contact', text: t('nav.contactUs'), key: 'contact' },
        { link: joinUsUrl, text: t('nav.joinUs'), key: 'join' },
      ],
    },
    { link: '/download', text: t('nav.downloadCenter'), key: 'download' },
    { link: '/support/tutorial', text: t('nav.supportCenter'), key: 'tutorial' },
    {
      link: '/try',
      text: t('nav.productTry'),
      key: 'try',
    },
    { link: 'tel:400.900.3567', text: t('nav.contactPhone'), key: 'tel' },
  ];

  const resize = useCallback(() => {
    const isPhone = isMobile();
    if (isPhone) return;
    setClientWidth(window.innerWidth);
    if (navRef.current) {
      const childNodes = navRef.current?.childNodes;
      if (childNodes) {
        setNavbar(navbar => {
          const nav = [...navbar];
          Array.from(childNodes).forEach((item: any, index: number) => {
            const position = item.getClientRects();
            const { left } = position[0];
            nav[index].left = left;
          });
          return nav;
        });
      }
    }
  }, []);
  useEffect(() => {
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, [resize]);

  useEffect(() => {
    resize();
  }, [resize]);

  return (
    <div className={cn(s['header'], props.headerClass)}>
      <div className={s['sale-bar']}>
        <div className={s.inner}>
          <ul className={s['sale']}>
            <li className={s['sale-li']}>
              <a type="primary" href={`/contact`} rel="noopener noreferrer">
                {t('nav.contactSales')}
              </a>
            </li>
            <li className={s['sale-li']}>
              <span>{t('nav.contactPhone')}</span>
            </li>
            <li className={s['sale-li']}>
              <LocaleSwitch />
            </li>
          </ul>
        </div>
      </div>
      <div className={cn(s['mob-only-item'], s['download-app-tips'], !showApp && s['hide'])}>
        <div className={s['left']}>
          <Image className={s['btn-delete']} onClick={deleteApp} src="/v2/images/header/delete.png" width={36} height={36} alt="delete" />
          <Image className={s['icon-xiaoyu']} src="/v2/images/header/xiaoyu.png" width={54} height={54} alt="xiaoyu" />
          <div className={s['main-title']}>
            <p className={s['header-title']}>{t('nav.xylinkApp')}</p>
            <p className={s['header-desc']}>{t('nav.noDistance')}</p>
          </div>
        </div>
        <div className={s['right']}>
          <div className={s['open-app']} onClick={openApp}>
            {t('nav.openNow')}
          </div>
        </div>
      </div>

      <div className={s['header-bar']}>
        <div className={s['header-container']}>
          <div className={cn(s['left'], locale === 'en-US' && s.en)}>
            <div className={s['logo']}>
              <a href="/">
                <Image src={locale === 'en-US' ? '/v2/images/xylink-logo-en.png' : '/v2/images/login-logo.png'} alt="logo" width="134" height="28" />
              </a>
            </div>
            <ul className={s['nav']} ref={navRef}>
              {navbar.map((item: any, index: number) => {
                return (
                  <li className={s['menu']} key={item.name}>
                    {item.external ? (
                      <a href={`${item.url}`} className={s['href']}>
                        {item.name}
                      </a>
                    ) : (
                      <CustomLink isNextLink={false} href={`${item.url}`} className={s['href']}>
                        {item.name}
                      </CustomLink>
                    )}
                    <div className={s['nav-bottom-line']}></div>
                    {item.children && (
                      <ul
                        className={cn(s['dropdown-menu'], index === 0 && s['row'], index === 0 && locale === 'en-US' && s['rowEn'])}
                        style={{ width: clientWidth, left: -(item.left || 0), paddingLeft: (item.left || 0) + 15, zIndex: -2 }}
                      >
                        {item.children}
                      </ul>
                    )}
                  </li>
                );
              })}
            </ul>
            {/* 移动端 */}
            <div className={cn(s.moblieMask, showMenu && s.active)}>
              <ul className={cn(s['mob-only-item'])}>
                {mobileNav.map((item: any, index: number) => {
                  return (
                    <li key={item.key}>
                      <a href={item.link} className={cn(index !== 0 && s['border__px-before1'])}>
                        {item.text}
                      </a>
                      {item.children?.length > 0 && (
                        <>
                          <div className={cn(s['icon-drawDown'])} onClick={onToggleActive}>
                            <Image src="/v2/images/header/trangle-icon.png" width="40" height="40" alt="trangle-icon" />
                          </div>
                          <ul className={s['solution_menu']}>
                            {item.children.map((item: any, index: number) => {
                              return (
                                <li key={item.key} className={cn(s['border__px'], index === 0 && s['border__px-before1'], !['emergency', 'join', 'api'].includes(item.key) && s['border-children1'])}>
                                  <a href={item.link} onClick={() => handleHonerLinkClick(item)}>
                                    {item.text}
                                  </a>
                                  {item.children?.length > 0 && (
                                    <>
                                      <div className={cn(s['icon-drawDown'])} onClick={onToggleActive}>
                                        <Image src="/v2/images/header/trangle-icon.png" width="40" height="40" alt="trangle-icon" />
                                      </div>
                                      <ul className={cn(s['solution_menu--third'])}>
                                        {item.children.map((item: any, index: number) => {
                                          return (
                                            <li key={item.key} className={cn(s['border__px'], index === 0 && !['hard', 'av'].includes(item.key) && s['border__px-before1'], s['border__px-before'])}>
                                              <a href={item.link}>{item.text}</a>
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    </>
                                  )}
                                </li>
                              );
                            })}
                          </ul>
                        </>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className={s['right']}>
            <Button className={s['btn-try']} category="nav" onClick={statisticTry} type="primary" color="green" href={`/try`}>
              {t('nav.productTry')}
            </Button>
            <Button className={s['btn-suport']} type="primary" category="nav" href={`/support/tutorial`}>
              {t('nav.supportCenter')}
            </Button>
          </div>
          <div className={cn(s.toggleMenu, showMenu && s.active)} onClick={() => setShowMenu(b => !b)}>
            <span className="line-top"></span>
            <span className="line-middle"></span>
            <span className="line-bottom"></span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
