import React, { useEffect, useState, useRef, useCallback } from 'react';
import { homeUrl } from 'constants/index';
import s from './index.module.scss';
import Image from 'next/image';
import cn from 'classnames';
import { mobileNav } from 'constants/index';
import Button from 'components/button';
import { platform, isMobile } from 'utils/index';
import getStore from 'utils/store';
import { handleHonerLinkClick, navbar } from './config';
import CustomLink from 'components/link';
interface IProps {
  onToggleApp: (visible: boolean) => void;
  headerClass?: string;
}
interface INavItem {
  name: string;
  url: string;
  children?: React.ReactNode;
  external?: boolean;
  left?: number;
}

function Header(props: IProps) {
  const [showMenu, setShowMenu] = useState(false);
  const [showApp, setShowApp] = useState(false);
  const [navArr, setNavbar] = useState<INavItem[]>(navbar);
  const [clientWidth, setClientWidth] = useState(0);
  const navRef = useRef<any>();
  const onToggleActive = (e: any) => {
    try {
      const currTarget = e.currentTarget;
      const brother = currTarget.nextElementSibling || currTarget.nextSibling;
      if (brother.className.indexOf('active') > -1) {
        currTarget.className = currTarget.className.replace(/\s+active/g, '');
        brother.className = brother.className.replace(/\s+active/g, '');
      } else {
        currTarget.className += ' active';
        brother.className += ' active';
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    props.onToggleApp(showApp);
  }, [props, showApp]);

  useEffect(() => {
    const isPhone = isMobile();
    const store = getStore();
    if (isPhone) {
      const userAgent = navigator.userAgent;
      if (userAgent.indexOf('XYLINK') >= 0) {
        setShowApp(false);
        return;
      }
      if (store.get('isCloseScanner') * 1 === 1) {
        const startTime = store.get('startTime') * 1;
        if (new Date().getTime() - startTime > 24 * 3600 * 1000) {
          setShowApp(true);
          store.set('isCloseScanner', 0);
          // 可有可无
          store.set('startTime', new Date().getTime());
        }
      } else {
        setShowApp(true);
        store.set('isCloseScanner', 0);
      }
    }
  }, []);

  const statisticTry = () => {
    const { _hmt = [] } = window;
    if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
      _hmt.push(['_trackEvent', 'header', '立即试用mobile']);
    } else {
      _hmt.push(['_trackEvent', 'header', '立即试用pc']);
    }
  };

  const openApp = () => {
    let openHash;
    const plat = platform();
    const { _hmt = [] } = window;
    if (plat.weixin) {
      if (plat.ios) {
        openHash = 'ios-wx';
        _hmt.push(['_trackEvent', '狰', 'weixindakaiApple']);
        _hmt.push(['_trackEvent', '狰', '微信打开iOS']);
      } else {
        openHash = 'android-wx';
        _hmt.push(['_trackEvent', '政', '微信打开Android']);
      }
    } else {
      if (plat.ios) {
        openHash = 'ios-browser';
        _hmt.push(['_trackEvent', '狰', '浏览器打开iOS']);
      } else {
        openHash = 'android-browser';
        _hmt.push(['_trackEvent', '政', '浏览器打开Android']);
      }
    }
    window.location.href = '/download?source=' + openHash;
  };

  const deleteApp = () => {
    setShowApp(false);
    const store = getStore();
    store.set('isCloseScanner', 1);
    store.set('startTime', new Date().getTime());
  };

  const resize = useCallback(() => {
    const isPhone = isMobile();
    if (isPhone) return;
    setClientWidth(window.innerWidth);
    if (navRef.current) {
      const childNodes = navRef.current?.childNodes;
      if (childNodes) {
        setNavbar(navArr => {
          const nav = [...navArr];
          Array.from(childNodes).forEach((item: any, index: number) => {
            const position = item.getClientRects();
            const { left } = position[0];
            nav[index].left = left;
          });
          return nav;
        });
      }
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, [resize]);

  useEffect(() => {
    resize();
  }, [resize]);

  return (
    <div className={cn(s['header'], props.headerClass)}>
      <div className={s['sale-bar']}>
        <div className={s.inner}>
          <ul className={s['sale']}>
            <li className={s['sale-li']}>
              <a type="primary" href={`/contact`} rel="noopener noreferrer">
                联系销售
              </a>
            </li>
            <li className={s['sale-li']}>
              <span>联系电话：400-900-3567</span>
            </li>
            {/* <li className={cn(s['sale-li'], s['language-switch'])}>
                <LocalSwitch />
              </li> */}
          </ul>
        </div>
      </div>
      <div className={cn(s['mob-only-item'], s['download-app-tips'], !showApp && s['hide'])}>
        <div className={s['left']}>
          <Image className={s['btn-delete']} onClick={deleteApp} src="/v2/images/header/delete.png" width={36} height={36} alt="delete" />
          <Image className={s['icon-xiaoyu']} src="/v2/images/header/xiaoyu.png" width={54} height={54} alt="xiaoyu" />
          <div className={s['main-title']}>
            <p className={s['header-title']}>小鱼易连APP</p>
            <p className={s['header-desc']}>让世界零距离</p>
          </div>
        </div>
        <div className={s['right']}>
          <div className={s['open-app']} onClick={openApp}>
            立即打开
          </div>
        </div>
      </div>

      <div className={s['header-bar']}>
        <div className={s['header-container']}>
          <div className={s['left']}>
            <div className={s['logo']}>
              <a href={homeUrl}>
                <Image src="/v2/images/login-logo.png" alt="logo" width="134" height="28" />
              </a>
            </div>
            <ul className={s['nav']} ref={navRef}>
              {navArr.map((item: any, index: number) => {
                const getDropdownMenuStyle = (item: INavItem) => ({
                  width: clientWidth,
                  left: -(item.left || 0),
                  paddingLeft: (item.left || 0) + 15,
                  zIndex: -2,
                });

                return (
                  <li className={s['menu']} key={item.name}>
                    {item.external ? (
                      <a href={`${item.url}`} className={s['href']}>
                        {item.name}
                      </a>
                    ) : (
                      <CustomLink href={`${item.url}`} isNextLink={false} className={s['href']}>
                        {item.name}
                      </CustomLink>
                    )}
                    <div className={s['nav-bottom-line']}></div>
                    {item.children && (
                      <ul className={cn(s['dropdown-menu'], index === 0 && s['row'])} style={getDropdownMenuStyle(item)}>
                        {item.children}
                      </ul>
                    )}
                  </li>
                );
              })}
            </ul>
            {/* 移动端导航 */}
            <div className={cn(s.moblieMask, showMenu && s.active)}>
              <ul className={cn(s['mob-only-item'])}>
                {mobileNav.map((item: any, index: number) => {
                  return (
                    <li key={item.key}>
                      <a href={item.link} className={cn(index !== 0 && s['border__px-before1'])}>
                        {item.text}
                      </a>
                      {item.children?.length > 0 && (
                        <>
                          <div className={cn(s['icon-drawDown'])} onClick={onToggleActive}>
                            <Image src="/v2/images/header/trangle-icon.png" width="40" height="40" alt="trangle-icon" />
                          </div>
                          <ul className={s['solution_menu']}>
                            {item.children.map((item: any, index: number) => {
                              return (
                                <li key={item.key} className={cn(s['border__px'], index === 0 && s['border__px-before1'], !['emergency', 'join', 'api'].includes(item.key) && s['border-children1'])}>
                                  <a
                                    href={item.link}
                                    onClick={() => {
                                      handleHonerLinkClick(item);
                                    }}
                                  >
                                    {item.text}
                                  </a>
                                  {item.children?.length > 0 && (
                                    <>
                                      <div className={cn(s['icon-drawDown-children'])} onClick={onToggleActive}>
                                        <Image src="/v2/images/header/trangle-icon.png" width="40" height="40" alt="trangle-icon" />
                                      </div>
                                      <ul className={cn(s['solution_menu--third'])}>
                                        {item.children.map((item: any, index: number) => {
                                          return (
                                            <li key={item.key} className={cn(s['border__px'], index === 0 && !['hard', 'av'].includes(item.key) && s['border__px-before1'], s['border__px-before'])}>
                                              <a href={item.link}>{item.text}</a>
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    </>
                                  )}
                                </li>
                              );
                            })}
                          </ul>
                        </>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className={s['right']}>
            <Button className={s['btn-try']} category="nav" onClick={statisticTry} color="green" type="primary" href={`/try`}>
              立即试用
            </Button>
            <Button className={s['btn-suport']} type="primary" category="nav" href={`/support/faq`}>
              支持中心
            </Button>
          </div>
          <div className={cn(s.toggleMenu, showMenu && s.active)} onClick={() => setShowMenu(b => !b)}>
            <span className="line-top"></span>
            <span className="line-middle"></span>
            <span className="line-bottom"></span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
