import { Button, ButtonProps } from 'antd';
import style from './index.module.scss';
import cn from 'classnames';
interface IProps extends ButtonProps {
  // 1. nav 指导航栏上立即试用、支持中心
  // 2. banner正常的大按钮
  // 3. viewMore是移动端列表的查看更多按钮
  // 4. 八大行业新的按钮
  category?: 'viewMore' | 'nav' | 'banner' | 'newBtn';
  color?: 'default' | 'green';
  btnWidth?: number;
}
export default function Home(props: IProps) {
  const { category = 'banner', color = 'default', children, className, btnWidth, ...rest } = props;

  return (
    <Button className={cn(style[category], color === 'green' && style.green, !!className && className)} {...rest} style={{ width: btnWidth }}>
      {children}
    </Button>
  );
}
