export const baseUrl = '';
export const homeUrl = '/';
//加入我们跳转链接
export const joinUsUrl = 'https://xylink.zhiye.com/';
//直播公约跳转链接
export const liveTreatyUrl = 'https://cdn.xylink.com/agreement/live-broadcast-convention.html';
//企业管理员跳转链接
export const adminUrl = 'https://vip.xylink.com/console/login';

// 支持中心菜单
export const supportNav = [
  // {
  //   link: '/support/tutorial',
  //   text: '使用指南',
  //   key: 'tutorial',
  // },
  {
    link: '/support/faq',
    text: '常见问题',
    key: 'faq',
  },
  {
    link: '/support/flow',
    text: '售后流程',
    key: 'flow',
  },
  {
    link: '/v2/announcement',
    text: '公告',
    key: 'announcement',
  },
];

// 移动端菜单
export const mobileNav = [
  {
    link: '/platform',
    text: '产品服务',
    key: 'platform',
    children: [
      {
        link: '/platform',
        text: '超融合视频平台',
        key: 'merge',
      },
      {
        link: '/hardware',
        text: '全连接视频终端',
        key: 'contactAll',
        children: [
          {
            link: '/hardware',
            text: '智能硬件',
            key: 'hard',
          },
          {
            link: '/client',
            text: '软件客户端',
            key: 'client',
          },
        ],
      },
      {
        link: '/sdk_api',
        text: '泛业务开发底座',
        key: 'api',
      },
    ],
  },
  {
    link: '/gov-solution',
    text: '解决方案',
    key: 'solution',
    children: [
      {
        link: '/gov-solution',
        text: '政务行业',
        key: 'governmentAffair',
      },
      {
        link: '/court-solution',
        text: '政法行业',
        key: 'governmentLaw',
      },
      {
        link: '/group-solution',
        text: '企业集团',
        key: 'group',
      },
      {
        link: '/finance-solution',
        text: '金融行业',
        key: 'finance',
      },
      {
        link: '/edu-solution',
        text: '教育行业',
        key: 'education',
      },
      {
        link: '/med-solution',
        text: '医疗行业',
        key: 'medical',
      },
      {
        link: '/watercon-solution',
        text: '水利行业',
        key: 'waterConservancy',
      },
      {
        link: '/er-solution',
        text: '应急行业',
        key: 'emergency',
      },
    ],
  },
  {
    link: '/advantage',
    text: '核心优势',
    key: 'advantage',
  },
  {
    link: '/about/about',
    text: '关于我们',
    key: 'about',
    children: [
      { link: '/about/about', text: '公司介绍', key: 'introduce' },
      { link: '/about/about#honer', text: '公司荣誉', key: 'honer' },
      { link: '/news', text: '公司新闻', key: 'news' },
      { link: '/about/contact', text: '联系我们', key: 'contact' },
      { link: joinUsUrl, text: '加入我们', key: 'join' },
    ],
  },
  {
    link: '/download',
    text: '下载中心',
    key: 'download',
  },
  {
    link: '/support/faq',
    text: '支持中心',
    key: 'tutorial',
  },
  {
    link: '/try',
    text: '立即试用',
    key: 'try',
  },
  {
    link: 'tel:400.900.3567',
    text: '联系电话：400-900-3567',
    key: 'tel',
  },
];

// 默认分页大小
export const pageSize = 20;

//   页面相关配置
export const pageConfig = {
  title: '小鱼易连_视频会议_远程会议_超融合云视频会议系统_政府/企业数字化转型服务商',
  desc: '云视频、超融合云视频、视频会议、远程会议、视频会议系统、企业数字化转型',
  keywords:
    '小鱼易连创新打造“高安全·超融合·全连接”云视频会议，远程会议系统，专注为党政军央企，金融，学校，医院等大型组织及企业提供超融合视讯协作应用服务，领先通过等保三级+可信云双认证，实现国密算法端到端全链路加密。',
  imgUrl: '',
};
interface IApiUrlMap {
  [key: string]: {
    clientApiUrl: string;
    serverApiUrl: string;
  };
}

export const apiUrlMap: IApiUrlMap = {
  txdev: {
    clientApiUrl: 'https://webdev.xylink.com',
    serverApiUrl: 'https://webdev.xylink.com',
  },
  txqa: {
    clientApiUrl: 'https://webtestqa.xylink.com',
    serverApiUrl: 'http://xy-website-xylinkservice.edu.svc.cluster.local:8080',
  },
  prd: {
    clientApiUrl: 'https://www.xylink.com',
    serverApiUrl: 'http://xy-website-xylinkservice.business.svc.cluster.local:8080',
  },
};
interface IObj {
  [key: string]: string;
}

export const langsText: IObj = {
  'zh-CN': '中文',
  'en-US': 'EN',
};
export const langsTextMobile: IObj = {
  'zh-CN': '简体中文',
  'en-US': 'English',
};

export const cdnPath = 'https://devcdn.xylink.com';
export const cdnImgPath = 'https://devcdn.xylink.com/xylink_website_doc/images';

export interface IAdvertise {
  key: string;
  link: string;
  imageUrl?: string;
  try?: boolean;
}

// 首页的广告
export const advertiseList: IAdvertise[] = [
  // {
  //   key: 'campus',
  //   link: '/campus2022',
  // },
  {
    key: 'newProduct',
    link: '/new_product_2024',
    imageUrl: '',
  },
  {
    key: 'market',
    link: '/news/1089',
    imageUrl: 'https://testdevcdn.xylink.com/h5/sdk_doc/static/website/home/4a360dc1404c7545b01edbe655f658c359dcc37428ee2a033a40fb150b42fd85.png',
    // try: true,
  },
  // {
  //   key: 'xinpin',
  //   link: '/news/1069.html',
  //   imageUrl: 'https://testdevcdn.xylink.com/h5/sdk_doc/static/website/85f0e9ecdec7fd1c4ded446f3dfb427ffb552475f5d4c17922afdf93df49c5b0.png',
  // },
  {
    // 暂时关闭：原product页面已删除
    key: 'cloudMeeting',
    link: '',
    imageUrl: 'https://devcdn.xylink.com/xylink_website_doc/images/index/pc/cloudmeeting-banner-text.png',
    try: true,
  },
  {
    key: 'whitePaper',
    link: '/news/946',
    imageUrl: 'https://devcdn.xylink.com/xylink_website_doc/images/index/pc/whiteBook-title.png',
  },
];
export interface ISolution {
  key: string;
  link: string;
  text: string;
}
export const solutionList: ISolution[] = [
  {
    key: 'gov',
    link: '/gov-solution',
    text: '政务行业方案',
  },
  {
    key: 'group',
    link: '/group-solution',
    text: '企业集团方案',
  },
  {
    key: 'edu',
    link: '/edu-solution',
    text: '教育行业方案',
  },
  {
    key: 'finance',
    link: '/finance-solution',
    text: '金融行业方案',
  },
  {
    key: 'medical',
    link: '/med-solution',
    text: '医疗行业方案',
  },
];

export interface IPartner {
  key: string;
  imgUrl: string;
}
export const partnerList: IPartner[] = [
  {
    key: 'partner1',
    imgUrl: 'https://testdevcdn.xylink.com/h5/sdk_doc/static/website/home/67bc77683a32b9807f81c111e853f40732be56922fd12b0bb998734d35e297b2.png',
  },
  {
    key: 'partner2',
    imgUrl: 'https://testdevcdn.xylink.com/h5/sdk_doc/static/website/home/9c2e6e72bdae58d4ed1cb228f3168fccbb2aded68464d297a6e3ff27c70f17eb.png',
  },
  {
    key: 'partner3',
    imgUrl: 'https://testdevcdn.xylink.com/h5/sdk_doc/static/website/home/9985aea0c792069b3ca1b1b96ae458eea87a446455f1391ec297d62a3675624f.png',
  },
  {
    key: 'partner4',
    imgUrl: 'https://testdevcdn.xylink.com/h5/sdk_doc/static/website/home/a486f870311717e6362ab866e6ec88962ea21c0e576829396d7c28b233f4e902.png',
  },
  {
    key: 'partner5',
    imgUrl: 'https://testdevcdn.xylink.com/h5/sdk_doc/static/website/home/c629f1927939ada1eda4a612cb47c9dfe270bc125212fcd7dec8131eefcbaeb9.png',
  },
  {
    key: 'partner6',
    imgUrl: 'https://testdevcdn.xylink.com/h5/sdk_doc/static/website/home/26df751e898f366f290d8e2d7b0ecfb6ef69eaeaef1e2d2a4f8c4b4de5b09392.png',
  },
  {
    key: 'partner7',
    imgUrl: 'https://testdevcdn.xylink.com/h5/sdk_doc/static/website/home/31e5e4439056ed70317a5c94fb9bd890956ad3f5ffec285fa049bc75bc76c712.png',
  },
  {
    key: 'partner8',
    imgUrl: 'https://testdevcdn.xylink.com/h5/sdk_doc/static/website/home/2ebc25d89d70aab3a89dcff3d1f4bb7fb2272a2ab9aeebe52900d6da79885112.png',
  },
  {
    key: 'partner9',
    imgUrl: 'https://testdevcdn.xylink.com/h5/sdk_doc/static/website/home/f31becc0deb880756a37e16090d1f3d6d6a245283308d071148f561ee22b6897.png',
  },
  {
    key: 'partner10',
    imgUrl: 'https://testdevcdn.xylink.com/h5/sdk_doc/static/website/home/1cc58c5ed59d43f3559a843187638870022c85e647a97910a22b066d01070233.png',
  },
  {
    key: 'partner11',
    imgUrl: 'https://testdevcdn.xylink.com/h5/sdk_doc/static/website/home/d6f38bade69e5e0468f49000ab3cb9aa027508d1b5c29e169853d918b69e897a.png',
  },
  {
    key: 'partner12',
    imgUrl: 'https://testdevcdn.xylink.com/h5/sdk_doc/static/website/home/8fe90129aa693fa5dc2d3e7753c3691c7242ab2d8d3d3a95aa20b0dda34bb972.png',
  },
];

// 支持中心banner图片
export const supportBanner = 'https://testdevcdn.xylink.com/h5/sdk_doc/static/website/home/5d4601cc7af366a1a8285e7c54e0bf3671dcd99e71965bfd992aeb8a11153a4b.jpeg';
export const supportBannerMobile = 'https://testdevcdn.xylink.com/h5/sdk_doc/static/website/home/1807fba99e84cf2a1a6754b3d4dc911b734d54011fcde812f3ab8c416a909579.jpg';
// 合作伙伴banner图片
export const partnerBanner = 'https://testdevcdn.xylink.com/h5/sdk_doc/static/website/home/c7203ba29787b6a3514472b7f183fa4c3aee88eeb07ec60578003815a3ddd4f0.jpeg';
export const partnerBannerMobile = 'https://testdevcdn.xylink.com/h5/sdk_doc/static/website/home/389726d38e433d3500c5b4d94c6a43c4456f687a56a93b6ef4dcb76b05b1ac44.jpg';
