import { joinUsUrl } from 'constants/index';
import style from './index.module.scss';
import cn from 'classnames';
import { jumpToAnchorAndReload } from 'utils/index';
import CustomLink from 'components/link';

interface navItem {
  needTitle?: boolean;
  name?: string;
  url?: string;
  external?: boolean;
  test?: boolean;
  show?: boolean;
  children?: {
    name: string;
    url: string;
    external?: boolean;
    //新页面打开
    openInNewPage?: boolean;
  }[];
}

export const handleHonerLinkClick = (item: any) => {
  if (item.name === '公司荣誉' || item.key === 'honer') {
    jumpToAnchorAndReload('honer');
  }
};

const navBarItem = (list: navItem[]) => {
  {
    return list.map((item: navItem, key: number) => {
      return (
        <li key={item.name || key}>
          {item.needTitle &&
            (item.external ? (
              <a href={item.url}>{item.name}</a>
            ) : (
              item.url && (
                <div className={style.title}>
                  <CustomLink isNextLink={false} href={item.url}>
                    {item.name}
                  </CustomLink>
                  {item.children && <span className={style.open}>&gt;</span>}
                </div>
              )
            ))}
          <ul className={cn(item.show && style.items, item.test && style.test)}>
            {item.children &&
              item.children.map((childrenItem: { name: string; url: string; external?: boolean; openInNewPage?: boolean }) => {
                return (
                  <li key={childrenItem.name}>
                    {childrenItem.external ? (
                      <a href={`${childrenItem.url}`} target={childrenItem.openInNewPage ? '_blank' : ''} rel="noopener noreferrer">
                        {childrenItem.name}
                      </a>
                    ) : (
                      <CustomLink isNextLink={false} href={`${childrenItem.url}`}>
                        {childrenItem.name}
                      </CustomLink>
                    )}
                  </li>
                );
              })}
          </ul>
        </li>
      );
    });
  }
};

export const navbar = [
  {
    name: '产品服务',
    url: `/platform`,
    children: (
      <>
        {navBarItem([
          {
            name: '超融合视频平台',
            url: `/platform`,
            show: true,
            needTitle: true,
          },
          {
            name: '全连接视频终端',
            url: `/hardware`,
            show: true,
            needTitle: true,
            children: [
              {
                name: '智能硬件',
                url: `/hardware`,
              },
              {
                name: '软件客户端',
                url: `/client`,
              },
            ],
          },
          {
            name: '泛业务开发底座',
            url: `/sdk_api`,
            needTitle: true,
            show: true,
          },
        ])}
      </>
    ),
  },
  {
    name: '解决方案',
    url: '/gov-solution',
    children: (
      <>
        {navBarItem([
          {
            needTitle: false,
            children: [
              {
                name: '政务行业',
                url: '/gov-solution',
              },
              {
                name: '政法行业',
                url: '/court-solution',
              },
              {
                name: '企业集团',
                url: '/group-solution',
              },
              {
                name: '金融行业',
                url: '/finance-solution',
              },
            ],
          },
          {
            needTitle: false,
            children: [
              {
                name: '教育行业',
                url: '/edu-solution',
              },
              {
                name: '医疗行业',
                url: '/med-solution',
              },
              {
                name: '水利行业',
                url: '/watercon-solution',
              },
              {
                name: '应急行业',
                url: '/er-solution',
              },
            ],
          },
        ])}
      </>
    ),
  },
  {
    name: '核心优势',
    url: '/advantage',
  },
  {
    name: '关于我们',
    url: '/about/about',
    external: true,
    children: (
      <>
        {navBarItem([
          {
            needTitle: false,
            children: [
              {
                name: '公司介绍',
                url: '/about/about',
              },
              {
                name: '公司荣誉',
                url: '/about/about#honer',
                external: true,
              },
              {
                name: '公司新闻',
                url: '/news',
              },
            ],
          },
          {
            needTitle: false,
            children: [
              {
                name: '联系我们',
                url: '/about/contact',
              },
              {
                name: '加入我们',
                url: joinUsUrl,
                external: true,
                openInNewPage: true,
              },
            ],
          },
        ])}
      </>
    ),
  },
  {
    name: '下载中心',
    url: '/download',
    external: true,
  },
];
